
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: $color-scrollbar-track;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $color-scrollbar-thumb;
  border: 2px solid $color-scrollbar-thumb-border;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $color-scrollbar-thumb-hover;
}
