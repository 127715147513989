#notification-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-overlay;
  z-index: 1001;
}

.notification-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 400px;
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px $color-shadow-dark;
}

#notification-modal button {
  display: block;
  margin: 20px auto 0;
}
