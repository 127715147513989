@font-face {
  font-family: 'kirbyss';
  src: url('../fonts/kirby.ttf');
}
* {
  font-family: 'kirbyss', Arial, sans-serif;
  line-height: 1.5em;
  letter-spacing: 0.1em;

}
body, html {
  margin: 0;
  padding: 0;
  color: #fff;
  background: $color-bg-gradient;
  height: 100%;
  overflow-x: hidden;

}
h1, h2, h3 {
  font-family: 'kirbyss', Arial, sans-serif;
}
#wrapper {
  display: flex;
  height: 100%;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: $color-accent;
  position: relative;
  transition: color 0.3s, transform 0.3s;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.1em;
    background: #a8a8f8;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s;
  }

  &:hover {
    color: $color-accent-hover;
    transform: scale(1.05);
    &:before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  &:active, &:focus {
    outline: none;
    color: $color-accent-active;
  }
}

.center {
  text-align: center;
}