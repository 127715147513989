/* Modal styles */
#wallet-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-overlay;
  z-index: 1000;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 400px;
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px $color-shadow-dark;
}

.wallet-options {
  list-style: none;
  padding: 0;
}

.wallet-options li {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;
}

.wallet-options img {
  margin-right: 10px;
  width: 30px;
  height: 30px;
}

#walletconnect-option.button img {
  width: 24px;
  height: 24px;
}

#wallet-modal button {
  display: block;
  margin: 20px auto 0;
}

@media screen and (max-width: 520px) {
  .wallet-options img {
    width: 20px;
    height: 20px;
  }
  
  #walletconnect-option.button img {
    width: 14px;
    height: 14px;
  }
  .modal-content {
    width: 100%;
    max-width: 100%;
    padding: 10px;
  }
}